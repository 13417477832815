var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { C00Ping } from "./clientbound/C00Ping";
import { C01Handshake } from "./clientbound/C01Handshake";
import { C02BroadcastChange } from "./clientbound/C02BroadcastChange";
import { C03LoginResult } from "./clientbound/C03LoginResult";
import { C04RouteList } from "./clientbound/C04RouteList";
import { C05Product } from "./clientbound/C05Product";
import { C06Route } from "./clientbound/C06Route";
import { C07Package } from "./clientbound/C07Package";
import { C08Order } from "./clientbound/C08Order";
import { C09DeliveryData } from "./clientbound/C09DeliveryData";
import { C10UserListResponse } from "./clientbound/C10UserListResponse";
import { C11Loading } from "./clientbound/C11Loading";
import { C12Threads } from "./clientbound/C12Threads";
import { C20OrderProduct } from "./clientbound/C20OrderProduct";
import { C21OrderPayment } from "./clientbound/C21OrderPayment";
import { C22ShippingCarrier } from "./clientbound/C22ShippingCarrier";
import { C23ShippingOrderProduct } from "./clientbound/C23ShippingOrderProduct";
import { C24Components } from "./clientbound/C24Components";
import { C25OrderDelivery } from "./clientbound/C25OrderDelivery";
import { C26Address } from "./clientbound/C26Address";
import { C27WarehouseComponent } from "./clientbound/C27WarehouseComponent";
import { C28ComponentProduct } from "./clientbound/C28ComponentProduct";
import { C29SupplierProduct } from "./clientbound/C29SupplierProduct";
import { C30Supplier } from "./clientbound/C30Supplier";
import { C31SupplierProductOrder } from "./clientbound/C31SupplierProductOrder";
import { C32SupplierComponent } from "./clientbound/C32SupplierComponent";
import { C33RoutePoint } from "./clientbound/C33RoutePoint";
import { C34WarehouseComponentProductDemand } from "./clientbound/C34WarehouseComponentProductDemand";
import { C35Customer } from "./clientbound/C35Customer";
import { C36ProductOpinion } from "./clientbound/C36ProductOpinion";
import { C38Manufacturing } from "./clientbound/C38Manufacturing";
import { C39ManufacturingWorkstation } from "./clientbound/C39ManufacturingWorkstation";
import { C40ManufacturingComponent } from "./clientbound/C40ManufacturingComponent";
import { C41WarehouseStockTalking } from "./clientbound/C41WarehouseStockTalking";
import { C42Motivation } from "./clientbound/C42Motivation";
import { C43WarehouseMaterial } from "./clientbound/C43WarehouseMaterial";
import { C44ManufacturingCost } from "./clientbound/C44ManufacturingCost";
import { C45WarehouseLocation } from "./clientbound/C45WarehouseLocation";
import { C46Materials } from "./clientbound/C46Materials";
import { C47ManufacturingOperators } from "./clientbound/C47ManufacturingOperators";
import { C48Price } from "./clientbound/C48Price";
import { C50Bookkeeping } from "./clientbound/C50Bookkeeping";
import { C51WarehouseComponentPacking } from "./clientbound/C51WarehouseComponentPacking";
import { C52DataScanner } from "./clientbound/C52DataScanner";
import { PacketClient } from "./PacketClient";
import { S00Ping } from "./serverbound/S00Ping";
export class EmpireException {
    constructor(code, errorMsg) {
        this.code = code;
        this.errorMsg = errorMsg;
    }
}
export class EmpireConnection {
    static setWSUrl(url) {
        EmpireConnection.WS_URL = url;
    }
    static getInstance() {
        return EmpireConnection.instance;
    }
    constructor() {
        this.lastRequestId = 0;
        this.lastPacket = Date.now();
        this.queue = [];
        this.socket = undefined;
        this.listeners = new Map();
        this.respHandlers = new Map();
        this.pingMap = new Map();
        this.started = false;
        this.callback = () => { };
        this.sendPacketCallback = (packet) => { };
        this.errorCallback = (error) => {
            console.error(error);
        };
        this.lastLat = 0;
        this.lastLng = 0;
        this.lastTrackTime = 0;
        this.startGeoWatch();
    }
    setErrorCallback(callback) {
        this.errorCallback = callback;
    }
    setSendPacketCallback(callback) {
        this.sendPacketCallback = callback;
    }
    start(callback) {
        if (this.started) {
            return;
        }
        this.started = true;
        this.callback = callback;
        setInterval(() => this.pingLoop(), 3000);
        this.connect();
    }
    addListener(channel, watch) {
        if (!this.listeners.has(channel)) {
            this.listeners.set(channel, []);
        }
        this.listeners.get(channel).push(watch);
        return () => {
            this.listeners.set(channel, this.listeners.get(channel).filter((w) => w !== watch));
        };
    }
    connect() {
        if (this.socket !== undefined) {
            this.socket.close();
        }
        console.log("Connecting...");
        this.lastPacket = Date.now();
        this.socket = new WebSocket(EmpireConnection.WS_URL);
        this.socket.onopen = (ev) => {
            console.log("Connection established");
            this.callback();
        };
        this.socket.onmessage = (event) => {
            this.lastPacket = Date.now();
            const packetData = new PacketClient();
            Object.assign(packetData, JSON.parse(event.data));
            EmpireConnection.deserializePacket(packetData);
            this.handlePacket(packetData);
        };
        this.socket.onclose = (event) => {
            if (event.wasClean) {
                console.log(`[Empire] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            }
            else {
                console.log(`[close] Connection closed, code=${event.code}: reason=${event.reason}`);
            }
        };
        this.socket.onerror = (error) => {
            console.log("Connection error", error);
        };
    }
    static deserializePacket(packetData) {
        const packetType = EmpireConnection.packetMapClientbound[packetData.getPacketId()];
        if (packetType === undefined) {
            packetData.setErrorMsg(`Unknown packet id ${packetData.getPacketId()}`);
            console.warn(packetData.getErrorMsg());
            return undefined;
        }
        const packet = new packetType.prototype.constructor();
        if (packet.getPacketID() !== packetData.getPacketId()) {
            packetData.setErrorMsg(`Packet id mismatch: expected ${packet.getPacketID()}, got ${packetData.getPacketId()}`);
            console.warn(packetData.getErrorMsg());
            return undefined;
        }
        Object.assign(packet, packetData.getPacketPayload());
        packetData.setPacket(packet);
    }
    send(packet_1) {
        return __awaiter(this, arguments, void 0, function* (packet, timeout = 30000) {
            this.sendPacketCallback(packet);
            let resolved = false;
            return new Promise((resolve, reject) => {
                if (this.socket === undefined || this.socket.readyState !== WebSocket.OPEN) {
                    this.queue.push(() => __awaiter(this, void 0, void 0, function* () {
                        try {
                            resolved = true;
                            resolve(yield this.send(packet, timeout));
                        }
                        catch (e) {
                            reject(e);
                        }
                    }));
                    return;
                }
                const packetData = {
                    requestId: this.lastRequestId++,
                    packetId: packet.getPacketID(),
                    payload: packet
                };
                setTimeout(() => {
                    const text = `Timeout with requestId=${packetData.requestId}, packetId=${packetData.packetId}`;
                    if (!resolved) {
                        console.error(`Packet timeout`, packetData);
                        this.errorCallback(text);
                    }
                    reject(text);
                }, timeout);
                if (packet instanceof S00Ping) {
                    this.pingMap.set(packet.pingTime, packetData.requestId);
                }
                this.respHandlers.set(packetData.requestId, (response) => {
                    var _a;
                    if (response.getErrorCode() !== 0) {
                        this.errorCallback(`Error with requestId=${packetData.requestId}, packetId=${packetData.packetId}: ${(_a = response.getErrorMsg()) !== null && _a !== void 0 ? _a : response.getErrorCode()}`);
                        resolved = true;
                        reject(new EmpireException(response.getErrorCode(), response.getErrorMsg()));
                    }
                    else if (response.getErrorMsg() !== null && response.getErrorMsg() !== undefined) {
                        this.errorCallback(response.getErrorMsg());
                        resolved = true;
                        reject(response.getErrorMsg());
                    }
                    else {
                        resolved = true;
                        resolve(response.getPacket());
                    }
                });
                this.socket.send(JSON.stringify(packetData));
            });
        });
    }
    pingLoop() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.socket === undefined) {
                console.log("Reconnecting: socket undefined");
                this.connect();
                return;
            }
            if (this.socket.readyState === WebSocket.CLOSING || this.socket.readyState === WebSocket.CLOSED) {
                console.log(`Reconnecting: socket closed (${this.socket.readyState})`);
                this.connect();
                return;
            }
            if (Date.now() - this.lastPacket > 10000) {
                console.log(`Reconnecting: last packet ${Date.now() - this.lastPacket} ms ago`);
                this.connect();
                return;
            }
            if (this.socket !== undefined && this.socket.readyState === WebSocket.OPEN) {
                try {
                    const response = yield this.send(new S00Ping(Date.now(), this.lastLat, this.lastLng, this.lastTrackTime));
                    console.debug(`Ping: ${Date.now() - response.timeReceived} ms (${Date.now()})`);
                }
                catch (e) {
                    if (typeof e === 'string' && e.startsWith('Timeout with requestId=')) {
                        return;
                    }
                    throw e;
                }
            }
        });
    }
    handlePacket(packet) {
        while (this.queue.length > 0) {
            this.queue.shift()();
        }
        let responseId = packet.getResponseId();
        const pkt = packet.getPacket();
        if (pkt instanceof C00Ping) {
            const time = pkt.timeReceived;
            responseId = this.pingMap.get(time);
            this.pingMap.delete(time);
        }
        if (responseId !== undefined) {
            const handler = this.respHandlers.get(responseId);
            if (handler !== undefined) {
                try {
                    handler(packet);
                }
                catch (e) {
                    console.error(e);
                }
                this.respHandlers.delete(responseId);
            }
        }
        if (pkt instanceof C02BroadcastChange) {
            const watches = this.listeners.get(pkt.channel);
            if (watches !== undefined) {
                console.log(`Broadcasting ${pkt.channel}`, pkt.payload);
                try {
                    watches.forEach((watch) => watch(pkt.changeAction, pkt.payload, pkt.uniqueId));
                }
                catch (e) {
                    console.error(e);
                }
            }
        }
    }
    startGeoWatch() {
        navigator.geolocation.watchPosition(res => {
            this.lastLat = res.coords.latitude;
            this.lastLng = res.coords.longitude;
            this.lastTrackTime = res.timestamp;
        }, (err) => {
            console.log(err);
            this.lastLat = 0;
            this.lastLng = 0;
            this.lastTrackTime = -1;
        }, {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 0
        });
    }
}
EmpireConnection.instance = new EmpireConnection();
EmpireConnection.WS_URL = "wss://empire.pietrowe.pl/ws";
EmpireConnection.packetMapClientbound = [
    C00Ping,
    C01Handshake,
    C02BroadcastChange,
    C03LoginResult,
    C04RouteList,
    C05Product,
    C06Route,
    C07Package,
    C08Order,
    C09DeliveryData, // 9
    C10UserListResponse, // 10
    C11Loading, // 11
    C12Threads,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined, // 19
    C20OrderProduct, // 20
    C21OrderPayment, // 21
    C22ShippingCarrier,
    C23ShippingOrderProduct,
    C24Components,
    C25OrderDelivery,
    C26Address,
    C27WarehouseComponent,
    C28ComponentProduct,
    C29SupplierProduct, // 29
    C30Supplier, // 30
    C31SupplierProductOrder, // 31
    C32SupplierComponent,
    C33RoutePoint,
    C34WarehouseComponentProductDemand,
    C35Customer,
    C36ProductOpinion,
    undefined,
    C38Manufacturing,
    C39ManufacturingWorkstation, // 39
    C40ManufacturingComponent, // 40
    C41WarehouseStockTalking, // 41
    C42Motivation,
    C43WarehouseMaterial,
    C44ManufacturingCost,
    C45WarehouseLocation,
    C46Materials,
    C47ManufacturingOperators,
    C48Price,
    null, //49
    C50Bookkeeping, // 50
    C51WarehouseComponentPacking, // 51
    C52DataScanner
];
