import { Packet } from "../Packet";
export class S43WarehouseMaterial extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 43;
    }
}
(function (S43WarehouseMaterial) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL"] = 0] = "GET_ALL";
        Action[Action["CREATE"] = 1] = "CREATE";
        Action[Action["GET_ONE_RICH"] = 2] = "GET_ONE_RICH";
        Action[Action["SET_PARAM"] = 3] = "SET_PARAM";
        Action[Action["DELETE"] = 4] = "DELETE";
        Action[Action["ADD_SUPPLY"] = 5] = "ADD_SUPPLY";
        Action[Action["GET_ALL_WAREHOUSE_MATERIALS_BY_MATERIAL_ID_AND_PARAMS"] = 6] = "GET_ALL_WAREHOUSE_MATERIALS_BY_MATERIAL_ID_AND_PARAMS";
        Action[Action["SET_INVENTORY"] = 7] = "SET_INVENTORY";
        Action[Action["DELETE_SUPPLY"] = 8] = "DELETE_SUPPLY";
    })(Action = S43WarehouseMaterial.Action || (S43WarehouseMaterial.Action = {}));
    ;
})(S43WarehouseMaterial || (S43WarehouseMaterial = {}));
