import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
      behavior: 'smooth'
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      children: [
        { path: "/", name: "Stats", component: () => import('../views/Todo.vue') },
        { path: "/scanner/list", name: "Terminal devices", component: () => import('../views/scanner/ScannerList.vue') },
        { path: "/scanner/emulation", name: "Terminal", component: () => import('../views/scanner/Emulation.vue') },
        { path: "/warehouse/packages", name: "Warehouse packages", component: () => import('../views/Todo.vue') },
        { path: "/warehouse/packages/stocktaking", name: "Warehouse packages stocktaking", component: () => import('../views/warehouse/packages/StockTalkingList.vue') },
        { path: "/warehouse/sectors", name: "Warehouse sectors", component: () => import('../views/warehouse/reception/Sectors.vue') },
        { path: "/warehouse/materials/list", name: "Warehouse Materials List", component: () => import('../views/warehouse/materials/List.vue') },
        { path: "/warehouse/components/list", name: "Warehouse components list", component: () => import('../views/warehouse/components/List.vue') },
        { path: "/warehouse/components/productpicking", name: "Warehouse components product picking", component: () => import('../views/warehouse/components/ProductPicking.vue') },
        { path: "/warehouse/components/futureproductlist", name: "Warehouse components future product picking", component: () => import('../views/warehouse/components/FutureProductList.vue') },
        { path: "/warehouse/components/bincomponentlist", name: "Warehouse components bin component list", component: () => import('../views/warehouse/components/bins/BinComponentList.vue') },
        { path: "/orders/all", name: "all orders", component: () => import('../views/order/List.vue') },
        { path: "/orders/refundList", name: "refund list", component: () => import('../views/order/RefundList.vue') },
        { path: "/orders/assigments", name: "assigments", component: () => import('../views/Todo.vue') },
        { path: "/supplier/list", name: "supplier list", component: () => import('../views/supplier/List.vue') },
        { path: "/supplier/packages", name: "Supplier packages list", component: () => import('../views/supplier/Packages.vue') },
        { path: "/supplier/product/list", name: "supplier product list", component: () => import('../views/supplier/products/ProductList.vue') },
        { path: "/supplier/product/orderList", name: "order list", component: () => import('../views/supplier/products/OrderList.vue') },
        { path: "/supplier/product/bulkOrderList", name: "bulk order list", component: () => import('../views/Todo.vue') },
        { path: "/supplier/component/demandList", name: "component demand list", component: () => import('../views/supplier/components/DemandList.vue') },
        { path: "/supplier/component/orderList", name: "component order list", component: () => import('../views/supplier/components/OrderList.vue') },
        { path: "/supplier/component/bulkOrderList", name: "component bulk order list", component: () => import('../views/supplier/components/BulkOrderList.vue') },
        { path: "/supplier/component/productList", name: "component product list", component: () => import('../views/supplier/components/ProductList.vue') },
        { path: "/supplier/reception/packages", name: "supplier package reception", component: () => import('../views/Todo.vue') },
        { path: "/supplier/reception/supplierComponents", name: "supplier component reception", component: () => import('../views/warehouse/reception/SupplierComponents.vue') },
        { path: "/supplier/reception/manufacturingComponents", name: "manufacturing component reception", component: () => import('../views/warehouse/reception/ManufacturingComponents.vue') },
        { path: "/shipping/empire/shippingMap", name: "delivery", component: () => import('../views/shipping/Map.vue') },
        { path: "/delivery/vehicles", name: "vehicles", component: () => import('../views/shipping/vehicles/List.vue') },
        { path: "/delivery/routes", name: "routes", component: () => import('../views/shipping/RouteList.vue') },
        { path: "/customers/all-threads", name: "AllThreads", component: () => import('../views/customers/ThreadList.vue') },
        { path: "/products/list", name: "ProductList", component: () => import('../views/Todo.vue') },
        { path: "/products/optionMaps", name: "ProductOptionMaps", component: () => import('../views/Todo.vue') },
        { path: "/products/opinions/list", name: "ProductOpinionList", component: () => import('../views/product/opinion/List.vue') },
        { path: "/websites/list", name: "WebsitesList", component: () => import('../views/Todo.vue') },
        { path: "/websites/category/list", name: "CategoryList", component: () => import('../views/Todo.vue') },
        { path: "/shipping/carriers", name: "Carriers", component: () => import('../views/shipping/carriers/List.vue') },
        { path: "/shipping/empire/drivers", name: "Drivers", component: () => import('../views/shipping/Drivers.vue') },
        { path: "/production/components/categories", name: "Component Category List", component: () => import('../views/Todo.vue') },
        { path: "/production/components/all", name: "Component List", component: () => import('../views/supplier/components/AllComponents.vue') },
        { path: "/supplier/component/productList", name: "Product List", component: () => import('../views/Todo.vue') },
        { path: "/workers/all", name: "Workers", component: () => import('../views/worker/List.vue') },
        { path: "/workers/roles", name: "Roles", component: () => import('../views/Todo.vue') },
        { path: "/workers/competenceList", name: "Competence List", component: () => import('../views/worker/CompetenceList.vue') },
        { path: "/workers/competenceMatrixList", name: "Competence Matrix List", component: () => import('../views/worker/CompetenceMatrixList.vue') },
        { path: "/manufacturing/components/sessionList", name: "Session List", component: () => import('../views/manufacturing/SessionList.vue') },
        { path: "/manufacturing/components/manufacturingComponentList", name: "Manufacturing Component List 1", component: () => import('../views/manufacturing/ManufacturingComponentList.vue') },
        { path: "/manufacturing/components/processList", name: "Process List", component: () => import('../views/manufacturing/ProcessList.vue') },
        { path: "/manufacturing/components/workstationList", name: "Workstation List", component: () => import('../views/manufacturing/WorkstationList.vue') },
        { path: "/manufacturing/components/platformList", name: "Platform List", component: () => import('../views/manufacturing/PlatformList.vue') },
        { path: "/manufacturing/components/itemList", name: "Manufacturing Component List 2", component: () => import('../views/manufacturing/ItemList.vue') },
        { path: "/manufacturing/components/operators", name: "Operator List", component: () => import('../views/Todo.vue') },
        { path: "/supplier/materials/list", name: "Supplier Materials List", component: () => import('../views/supplier/materials/List.vue') },
        { path: "/manufacturing/components/costs/list", name: "Costs", component: () => import('../views/manufacturing/costs/CostList.vue') },
        { path: "/manufacturing/operators/list", name: "Manufacturing Operators", component: () => import('../views/manufacturing/operators/OperatorList.vue') },
        { path: "/bookkeeping/journal", name: "Journal", component: () => import('../views/bookkeeping/journals/Journal.vue') },
        { path: "/bookkeeping/accounts", name: "Accounts", component: () => import('../views/bookkeeping/accounts/AccountList.vue') },
        { path: "/bookkeeping/journalSchemas", name: "Journal Schemas", component: () => import('../views/bookkeeping/schemas/SchemasList.vue') },
        { path: "/bookkeeping/bankAccounts", name: "Bank Accounts", component: () => import('../views/bookkeeping/BankAccountsList.vue') },
        { path: "/bookkeeping/sourceDocuments", name: "Source Documents", component: () => import('../views/bookkeeping/sourceDocuments/SourceDocumentList.vue') },
        { path: "/bookkeeping/evidence/invoiceVat", name: "Invoice VAT", component: () => import('../views/bookkeeping/evidence/invoice/InvoiceVatList.vue') },
        { path: "/bookkeeping/evidence/bill", name: "Bill", component: () => import('../views/bookkeeping/evidence/bill/BillList.vue') },
        { path: "/bookkeeping/evidence/balanceTransfer", name: "Balance transfer", component: () => import('../views/bookkeeping/evidence/BalanceTransferList.vue') },
        { path: "/bookkeeping/evidence/inventory", name: "Inventory", component: () => import('../views/bookkeeping/evidence/inventory/InventoryList.vue') },
        { path: "/bookkeeping/evidence/labor", name: "Labor", component: () => import('../views/bookkeeping/evidence/LaborList.vue') },
        { path: "/bookkeeping/evidence/payment", name: "Payments", component: () => import('../views/bookkeeping/evidence/Payments.vue') },
        { path: "/bookkeeping/evidence/accountingOrder", name: "Accounting order", component: () => import('../views/bookkeeping/evidence/accountingorder/AccountingOrder.vue') },
        { path: "/bookkeeping/transactions/bank", name: "Bank Transactions", component: () => import('../views/bookkeeping/transactions/bank/BankTransactionList.vue') },
        { path: "/bookkeeping/transactions/cash", name: "Cash Transactions", component: () => import('../views/bookkeeping/transactions/cash/CashTransactionList.vue') },
        { path: "/bookkeeping/transactions/masspaymentDeposit", name: "Mass payments", component: () => import('../views/bookkeeping/transactions/deposit/TransactionMasspaymentDepositList.vue') },
        { path: "/bookkeeping/declarations/pl-jpk", name: "Polish JPK", component: () => import('../views/bookkeeping/declarations/PolandList.vue') },
        { path: "/bookkeeping/declarations/pl-jpk-schema", name: "Polish JPK Schemes", component: () => import('../views/bookkeeping/declarations/PolishJPKSchemes.vue') },
        { path: "/bookkeeping/contractors", name: "Contractors", component: () => import('../views/bookkeeping/contractors/List.vue') },
        { path: "/bookkeeping/reports/schemas", name: "Reports Schemas", component: () => import('../views/bookkeeping/reports/Schemas.vue') },
        { path: "/bookkeeping/reports/period-list", name: "Reports List", component: () => import('../views/bookkeeping/reports/PeriodList.vue') },

      ]
    },
    {
      path: '/auth',
      component: () => import('../views/auth/Auth.vue'),
      name: 'auth',
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('../views/auth/LoginView.vue')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('../views/auth/RegisterView.vue')
        }

      ]
    }
  ]
})

export default router
