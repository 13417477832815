import { Packet } from "../Packet";
export class S38Manufacturing extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 38;
    }
}
(function (S38Manufacturing) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL_MANUFACTURING_COMPONENTS"] = 1] = "GET_ALL_MANUFACTURING_COMPONENTS";
        Action[Action["GET_ALL_NOT_MANUFACTURING_COMPONENT_LIST"] = 2] = "GET_ALL_NOT_MANUFACTURING_COMPONENT_LIST";
        Action[Action["ADD_MANUFACTURING_COMPONENT"] = 3] = "ADD_MANUFACTURING_COMPONENT";
        Action[Action["GET_MANUFACTURING_COMPONENT_RICH"] = 4] = "GET_MANUFACTURING_COMPONENT_RICH";
        Action[Action["ADD_MANUFACTURING_COMPONENT_METADATA"] = 5] = "ADD_MANUFACTURING_COMPONENT_METADATA";
        Action[Action["GET_ALL_PROCESSES"] = 6] = "GET_ALL_PROCESSES";
        Action[Action["ADD_PROCESS"] = 7] = "ADD_PROCESS";
        Action[Action["GET_COMPONENT_PROCESS_ADD_INFO"] = 8] = "GET_COMPONENT_PROCESS_ADD_INFO";
        Action[Action["ADD_COMPONENT_PROCESS"] = 9] = "ADD_COMPONENT_PROCESS";
        Action[Action["GET_ALL_AVALIABLE_BULK_ORDER_FOR_SESSION"] = 10] = "GET_ALL_AVALIABLE_BULK_ORDER_FOR_SESSION";
        Action[Action["CREATE_SESSION"] = 11] = "CREATE_SESSION";
        Action[Action["GET_ALL_SESSIONS"] = 12] = "GET_ALL_SESSIONS";
        Action[Action["GET_WORKSTATION_LIST"] = 13] = "GET_WORKSTATION_LIST";
        Action[Action["ADD_WORKSTATION"] = 14] = "ADD_WORKSTATION";
        Action[Action["GET_WORKING_SESSIONS"] = 15] = "GET_WORKING_SESSIONS";
        Action[Action["GET_WORKSTATION"] = 16] = "GET_WORKSTATION";
        Action[Action["ADD_WORKSTATION_PROCESS"] = 17] = "ADD_WORKSTATION_PROCESS";
        Action[Action["GET_WORKSTATION_START_OPEN"] = 18] = "GET_WORKSTATION_START_OPEN";
        Action[Action["START_WORKSTATION"] = 19] = "START_WORKSTATION";
        Action[Action["SAVE_MANUFACTURING_METADATA_DEFAULT"] = 20] = "SAVE_MANUFACTURING_METADATA_DEFAULT";
        Action[Action["ADD_PLATFORM"] = 21] = "ADD_PLATFORM";
        Action[Action["GET_ALL_PLATFORM"] = 22] = "GET_ALL_PLATFORM";
        Action[Action["SAVE_PROCESS"] = 23] = "SAVE_PROCESS";
        Action[Action["REMOVE_MANUFACTURING_COMPONENT_PROCESS"] = 24] = "REMOVE_MANUFACTURING_COMPONENT_PROCESS";
        Action[Action["GET_MANUFACTURING_PLATFORM_RICH"] = 25] = "GET_MANUFACTURING_PLATFORM_RICH";
        Action[Action["GET_MANUFACTURING_ITEM_RICH"] = 26] = "GET_MANUFACTURING_ITEM_RICH";
        Action[Action["GET_ALL_ITEMS"] = 27] = "GET_ALL_ITEMS";
        Action[Action["GET_MANUFACTURING_OPERATORS"] = 28] = "GET_MANUFACTURING_OPERATORS";
        Action[Action["ADD_MANUFACTURING_OPERATOR"] = 29] = "ADD_MANUFACTURING_OPERATOR";
        Action[Action["LEAVE_OPERATOR_WORKSTATION"] = 30] = "LEAVE_OPERATOR_WORKSTATION";
        Action[Action["GET_CYCLE_TIME"] = 31] = "GET_CYCLE_TIME";
        Action[Action["SAVE_CYCLE_TIME"] = 32] = "SAVE_CYCLE_TIME";
        Action[Action["GET_ITEM_LOG"] = 33] = "GET_ITEM_LOG";
        Action[Action["GET_PROCESS_OPERATION_MULTIDRILL"] = 34] = "GET_PROCESS_OPERATION_MULTIDRILL";
        Action[Action["SAVE_PROCESS_OPERATION_MULTIDRILL"] = 35] = "SAVE_PROCESS_OPERATION_MULTIDRILL";
        Action[Action["GET_MANUFACTURING_COMPONENT_ITEM_READY_RECEPTION"] = 36] = "GET_MANUFACTURING_COMPONENT_ITEM_READY_RECEPTION";
        Action[Action["MOVE_ALL_ITEMS"] = 37] = "MOVE_ALL_ITEMS";
        Action[Action["MOVE_AFTER_MANUFACTURING_PROCESS"] = 38] = "MOVE_AFTER_MANUFACTURING_PROCESS";
        Action[Action["SET_MANUFACTURING_COMPONENT_PARENT"] = 39] = "SET_MANUFACTURING_COMPONENT_PARENT";
        Action[Action["REMOVE_CYCLE_TIME"] = 40] = "REMOVE_CYCLE_TIME";
        Action[Action["MOVE_ITEM"] = 41] = "MOVE_ITEM";
        Action[Action["FIND_SIMILAR_ITEMS"] = 42] = "FIND_SIMILAR_ITEMS";
        Action[Action["GET_MANUFACTURING_COMPONENT_COST"] = 43] = "GET_MANUFACTURING_COMPONENT_COST";
        Action[Action["GET_PRIORITY_MAP_BY_WORKSTATION"] = 44] = "GET_PRIORITY_MAP_BY_WORKSTATION";
        Action[Action["RECALCULATE_FINALIZED_LOG"] = 45] = "RECALCULATE_FINALIZED_LOG";
    })(Action = S38Manufacturing.Action || (S38Manufacturing.Action = {}));
    ;
})(S38Manufacturing || (S38Manufacturing = {}));
