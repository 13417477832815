import { Packet } from "../Packet";
export class S23SupplierOrderProduct extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 23;
    }
}
(function (S23SupplierOrderProduct) {
    let Action;
    (function (Action) {
        Action[Action["SET_PRIORITY"] = 1] = "SET_PRIORITY";
        Action[Action["CREATE"] = 2] = "CREATE";
        Action[Action["REMOVE"] = 3] = "REMOVE";
        Action[Action["PRINT_PACKAGE_LABEL"] = 4] = "PRINT_PACKAGE_LABEL";
        Action[Action["GET_ORDER_PRODUCT_LIST_RICH1"] = 5] = "GET_ORDER_PRODUCT_LIST_RICH1";
        Action[Action["GENERATE_BULK_ORDER"] = 6] = "GENERATE_BULK_ORDER";
    })(Action = S23SupplierOrderProduct.Action || (S23SupplierOrderProduct.Action = {}));
    ;
})(S23SupplierOrderProduct || (S23SupplierOrderProduct = {}));
