import { Packet } from "../Packet";
export class S50Bookkeeping extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 50;
    }
}
(function (S50Bookkeeping) {
    let Action;
    (function (Action) {
        Action[Action["GET_ALL_ACCOUNTS"] = 0] = "GET_ALL_ACCOUNTS";
        Action[Action["CREATE_ACCOUNT"] = 1] = "CREATE_ACCOUNT";
        Action[Action["GET_ALL_JOURNAL_RICH1"] = 2] = "GET_ALL_JOURNAL_RICH1";
        Action[Action["GET_CONTRACTOR"] = 3] = "GET_CONTRACTOR";
        Action[Action["GET_ALL_TRANSACTIONS_RICH1"] = 4] = "GET_ALL_TRANSACTIONS_RICH1";
        Action[Action["CREATE_CONTRACTOR"] = 5] = "CREATE_CONTRACTOR";
        Action[Action["GET_ALL_CONTRACTORS"] = 6] = "GET_ALL_CONTRACTORS";
        Action[Action["CREATE_INVOICE_VAT"] = 7] = "CREATE_INVOICE_VAT";
        Action[Action["GET_ASSIGNABLE_EVIDENCE_INVENTORY_RECEPTION_LIST"] = 8] = "GET_ASSIGNABLE_EVIDENCE_INVENTORY_RECEPTION_LIST";
        Action[Action["GET_ALL_SOURCE_DOCUMENT_RICH1"] = 9] = "GET_ALL_SOURCE_DOCUMENT_RICH1";
        Action[Action["SET_SOURCE_DOCUMENT_EVIDENCE_TYPE"] = 10] = "SET_SOURCE_DOCUMENT_EVIDENCE_TYPE";
        Action[Action["SET_SOURCE_DOCUMENT_NAME"] = 11] = "SET_SOURCE_DOCUMENT_NAME";
        Action[Action["RECALCULATE_SCHEMA"] = 12] = "RECALCULATE_SCHEMA";
        Action[Action["UPDATE_CONTRACTOR"] = 13] = "UPDATE_CONTRACTOR";
        Action[Action["SEARCH_EVIDENCE"] = 14] = "SEARCH_EVIDENCE";
        Action[Action["CREATE_EVIDENCE_PAYMENT"] = 15] = "CREATE_EVIDENCE_PAYMENT";
        Action[Action["GET_ALL_EVIDENCE_INVOICE_VAT_RICH1"] = 16] = "GET_ALL_EVIDENCE_INVOICE_VAT_RICH1";
        Action[Action["GET_ALL_EVIDENCE_PAYMENT_RICH1"] = 17] = "GET_ALL_EVIDENCE_PAYMENT_RICH1";
        Action[Action["SET_EVIDENCE_PAYMENT_MANY"] = 18] = "SET_EVIDENCE_PAYMENT_MANY";
        Action[Action["GET_EVIDENCE_PAYMENT_BY_TRANSACTION"] = 19] = "GET_EVIDENCE_PAYMENT_BY_TRANSACTION";
        Action[Action["CREATE_JOURNAL_OPERATION"] = 20] = "CREATE_JOURNAL_OPERATION";
        Action[Action["GET_EVIDENCE_INVOICE_RICH1"] = 21] = "GET_EVIDENCE_INVOICE_RICH1";
        Action[Action["GET_JOURNAL_RICH1"] = 22] = "GET_JOURNAL_RICH1";
        Action[Action["GET_ALL_CASH_TRANSACTIONS_RICH1"] = 23] = "GET_ALL_CASH_TRANSACTIONS_RICH1";
        Action[Action["GET_ALL_EVIDENCE_MASSPAYMENT_CHANNEL_RICH1"] = 24] = "GET_ALL_EVIDENCE_MASSPAYMENT_CHANNEL_RICH1";
        Action[Action["GET_ALL_EVIDENCE_MASSPAYMENT_DEPOSIT_RICH1"] = 25] = "GET_ALL_EVIDENCE_MASSPAYMENT_DEPOSIT_RICH1";
        Action[Action["SET_DECLARATION_INVOICE_VAT_POLISH_JPKv7_ENTRY"] = 26] = "SET_DECLARATION_INVOICE_VAT_POLISH_JPKv7_ENTRY";
        Action[Action["GET_ALL_DECLARATION_INVOICE_VAT_POLISH_JPKv7_RICH1"] = 27] = "GET_ALL_DECLARATION_INVOICE_VAT_POLISH_JPKv7_RICH1";
        Action[Action["GET_ALL_BANKACCOUNTS"] = 28] = "GET_ALL_BANKACCOUNTS";
        Action[Action["GET_ALL_EVIDENCE_ACCOUNTING_ORDER_RICH1"] = 29] = "GET_ALL_EVIDENCE_ACCOUNTING_ORDER_RICH1";
        Action[Action["GET_ALL_ACCOUNTS_RICH1"] = 30] = "GET_ALL_ACCOUNTS_RICH1";
        Action[Action["GET_EVIDENCE_PAYMENT_BY_ID_RICH1"] = 31] = "GET_EVIDENCE_PAYMENT_BY_ID_RICH1";
        Action[Action["SET_JOURNAL_OPERATION_CHANGELIST"] = 32] = "SET_JOURNAL_OPERATION_CHANGELIST";
        Action[Action["CREATE_EVIDENCE_ACCOUTINGORDER"] = 33] = "CREATE_EVIDENCE_ACCOUTINGORDER";
        Action[Action["GET_EVIDENCE_ACCOUNTINGORDER_BY_ID_RICH1"] = 34] = "GET_EVIDENCE_ACCOUNTINGORDER_BY_ID_RICH1";
        Action[Action["CREATE_CASH_TRANSACTION"] = 35] = "CREATE_CASH_TRANSACTION";
        Action[Action["GENERATE_DECLARATION_INVOICE_VAT_POLISH_JPKv7"] = 36] = "GENERATE_DECLARATION_INVOICE_VAT_POLISH_JPKv7";
        Action[Action["CREATE_EVIDENCE_MASSPAYMENT_CHANNEL"] = 37] = "CREATE_EVIDENCE_MASSPAYMENT_CHANNEL";
        Action[Action["REMOVE_EVIDENCE_PAYMENT"] = 38] = "REMOVE_EVIDENCE_PAYMENT";
        Action[Action["REMOVE_CASH_TRANSACTION"] = 39] = "REMOVE_CASH_TRANSACTION";
        Action[Action["GET_ONE_CASH_TRANSACTION_RICH1"] = 40] = "GET_ONE_CASH_TRANSACTION_RICH1";
        Action[Action["GET_ONE_TRANSACTION_MASSPAYMENT_DEPOSIT_RICH1"] = 41] = "GET_ONE_TRANSACTION_MASSPAYMENT_DEPOSIT_RICH1";
        Action[Action["GET_ONE_BANK_TRANSACTION_RICH1"] = 42] = "GET_ONE_BANK_TRANSACTION_RICH1";
        Action[Action["GET_ACCOUNT_JOURNAL_OPERATION_CHANGELIST"] = 43] = "GET_ACCOUNT_JOURNAL_OPERATION_CHANGELIST";
        Action[Action["SET_EVIDENCE_INVOICEVAT_INFO"] = 44] = "SET_EVIDENCE_INVOICEVAT_INFO";
        Action[Action["SET_EVIDENCE_INVOICEVAT_ENTRIES"] = 45] = "SET_EVIDENCE_INVOICEVAT_ENTRIES";
        Action[Action["REMOVE_EVIDENCE_INVOICEVAT"] = 46] = "REMOVE_EVIDENCE_INVOICEVAT";
        Action[Action["REMOVE_JOURNAL"] = 47] = "REMOVE_JOURNAL";
        Action[Action["GET_ALL_DECLARATION_INVOICE_VAT_POLISH_JPKv7"] = 48] = "GET_ALL_DECLARATION_INVOICE_VAT_POLISH_JPKv7";
        Action[Action["IMPORT_BANK_TRANSACTIONS"] = 49] = "IMPORT_BANK_TRANSACTIONS";
        Action[Action["SAVE_EVIDENCE_BALANCE_TRANSFER"] = 50] = "SAVE_EVIDENCE_BALANCE_TRANSFER";
        Action[Action["GET_ALL_EVIDENCE_BALANCE_TRANSFER_RICH"] = 51] = "GET_ALL_EVIDENCE_BALANCE_TRANSFER_RICH";
        Action[Action["DELETE_EVIDENCE_BALANCE_TRANSFER"] = 52] = "DELETE_EVIDENCE_BALANCE_TRANSFER";
        Action[Action["GET_EVIDENCE_BALANCE_TRANSFER_RICH"] = 53] = "GET_EVIDENCE_BALANCE_TRANSFER_RICH";
        Action[Action["GET_ALL_BILLS"] = 54] = "GET_ALL_BILLS";
        Action[Action["SET_BOOKKEEPING_JOURNAL_EVENTDATE"] = 55] = "SET_BOOKKEEPING_JOURNAL_EVENTDATE";
        Action[Action["SET_BOOKKEEPING_JOURNAL_OPERATION_INFO"] = 56] = "SET_BOOKKEEPING_JOURNAL_OPERATION_INFO";
        Action[Action["GET_EVIDENCE_INVENTORY_LIST_RICH1"] = 57] = "GET_EVIDENCE_INVENTORY_LIST_RICH1";
        Action[Action["SET_EVIDENCE_INVENTORY_RECEPTION"] = 58] = "SET_EVIDENCE_INVENTORY_RECEPTION";
        Action[Action["REMOVE_EVIDENCE_INVENTORY"] = 59] = "REMOVE_EVIDENCE_INVENTORY";
        Action[Action["GET_EVIDENCE_LABOR_LIST_RICH1"] = 60] = "GET_EVIDENCE_LABOR_LIST_RICH1";
        Action[Action["SET_EVIDENCE_LABOR"] = 61] = "SET_EVIDENCE_LABOR";
        Action[Action["GET_EVIDENCE_LABOR_RICH1"] = 62] = "GET_EVIDENCE_LABOR_RICH1";
        Action[Action["GET_ALL_LABOR_GROUPS"] = 63] = "GET_ALL_LABOR_GROUPS";
        Action[Action["GET_EVIDENCE_INVENTORY_RECEPTION_RICH1"] = 64] = "GET_EVIDENCE_INVENTORY_RECEPTION_RICH1";
        Action[Action["RECALCULATE_BOOKKEEPING_EVIDENCE_INVENTORY_RECEPTION"] = 65] = "RECALCULATE_BOOKKEEPING_EVIDENCE_INVENTORY_RECEPTION";
        Action[Action["OPERATION_SCHEMA_GET_ALL"] = 66] = "OPERATION_SCHEMA_GET_ALL";
        Action[Action["OPERATION_SCHEMA_GET_ONE_RICH"] = 67] = "OPERATION_SCHEMA_GET_ONE_RICH";
        Action[Action["OPERATION_SCHEMA_SAVE"] = 68] = "OPERATION_SCHEMA_SAVE";
        Action[Action["OPERATION_SCHEMA_EVAL"] = 69] = "OPERATION_SCHEMA_EVAL";
        Action[Action["OPERATION_SCHEMA_GET_ALL_RELEVANT"] = 70] = "OPERATION_SCHEMA_GET_ALL_RELEVANT";
        Action[Action["POLISH_DECLARATION_JPKv7_SCHEMA_GET_ALL"] = 71] = "POLISH_DECLARATION_JPKv7_SCHEMA_GET_ALL";
        Action[Action["POLISH_DECLARATION_JPKv7_SCHEMA_GET_ONE_RICH"] = 72] = "POLISH_DECLARATION_JPKv7_SCHEMA_GET_ONE_RICH";
        Action[Action["POLISH_DECLARATION_JPKv7_SCHEMA_SAVE"] = 73] = "POLISH_DECLARATION_JPKv7_SCHEMA_SAVE";
        Action[Action["POLISH_DECLARATION_JPKv7_SCHEMA_EVAL"] = 74] = "POLISH_DECLARATION_JPKv7_SCHEMA_EVAL";
        Action[Action["POLISH_DECLARATION_JPKv7_SCHEMA_GET_ALL_RELEVANT"] = 75] = "POLISH_DECLARATION_JPKv7_SCHEMA_GET_ALL_RELEVANT";
        Action[Action["SET_INVOICE_VAT_CONTRACTOR"] = 76] = "SET_INVOICE_VAT_CONTRACTOR";
        Action[Action["SAVE_TRANSACTION_MASSPAYMENT_DEPOSIT"] = 77] = "SAVE_TRANSACTION_MASSPAYMENT_DEPOSIT";
        Action[Action["DELETE_TRANSACTION_MASSPAYMENT_DEPOSIT"] = 78] = "DELETE_TRANSACTION_MASSPAYMENT_DEPOSIT";
        Action[Action["GET_ALL_REPORT_SCHEMAS"] = 79] = "GET_ALL_REPORT_SCHEMAS";
        Action[Action["GET_ONE_REPORT_SCHEMA_RICH"] = 80] = "GET_ONE_REPORT_SCHEMA_RICH";
        Action[Action["SAVE_REPORT_SCHEMA"] = 81] = "SAVE_REPORT_SCHEMA";
        Action[Action["GET_ONE_REPORT_SCHEMA_ENTRY_RICH"] = 82] = "GET_ONE_REPORT_SCHEMA_ENTRY_RICH";
        Action[Action["SET_REPORT_SCHEMA_ENTRY_ACCOUNT_SUM_TYPE"] = 83] = "SET_REPORT_SCHEMA_ENTRY_ACCOUNT_SUM_TYPE";
        Action[Action["GET_ALL_REPORT_PERIOD_LIST"] = 84] = "GET_ALL_REPORT_PERIOD_LIST";
        Action[Action["CREATE_REPORT_PERIOD"] = 85] = "CREATE_REPORT_PERIOD";
        Action[Action["GET_REPORT_PERIOD"] = 86] = "GET_REPORT_PERIOD";
        Action[Action["SET_REPORT_SCHEMA_ENTRY_CALC_VALUE"] = 87] = "SET_REPORT_SCHEMA_ENTRY_CALC_VALUE";
        Action[Action["GET_BILL_SCHEMA_LIST"] = 88] = "GET_BILL_SCHEMA_LIST";
        Action[Action["GET_BILL_SCHEMA"] = 89] = "GET_BILL_SCHEMA";
        Action[Action["SAVE_BILL_SCHEMA"] = 90] = "SAVE_BILL_SCHEMA";
        Action[Action["GET_EVIDENCE_BILL_RICH1"] = 91] = "GET_EVIDENCE_BILL_RICH1";
        Action[Action["CREATE_EVIDENCE_BILL"] = 92] = "CREATE_EVIDENCE_BILL";
        //next
        Action[Action["GET_COMPANYDATA_BY_VATNUMBER"] = 99] = "GET_COMPANYDATA_BY_VATNUMBER";
    })(Action = S50Bookkeeping.Action || (S50Bookkeeping.Action = {}));
    ;
})(S50Bookkeeping || (S50Bookkeeping = {}));
